import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import RouteRedirect from "../../utils/RouteRedirect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//pages
import PrivacyPolicy from "../PrivacyPolicy";

//# lazy routes
const Homepage = lazy(() => import("../HomePage"));
const AboutPageNew = lazy(() => import("../AboutPageNew"));
const ServicePage = lazy(() => import("../ServicePage"));
const MaintenancePage = lazy(() => import("../MaintancePage"));
const MaintenanceInner = lazy(() => import("../MaintenanceInner"));
const FitOutServices = lazy(() => import("../FitOutServices"));
const FitOutInner = lazy(() => import("../FitOutInner"));
const PortfolioPage = lazy(() => import("../PortfolioPage"));
const PortfolioInnerPage = lazy(() => import("../PortfolioInnerPage"));
const InvestmentSupport = lazy(() => import("../InvestmentSupport"));
const FaqPage = lazy(() => import("../FaqPage"));
const ContactPage = lazy(() => import("../NewContactPage"));
const ErrorPage = lazy(() => import("../ErrorPage"));
const BlogPage = lazy(() => import("../BlogPage"));
const BlogSinglePage = lazy(() => import("../BlogSinglePage"));
const Loader = lazy(() => import("../../components/Loader/Loader"));

//# route import end

function TrailingSlashRedirect() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location.pathname.endsWith("/")) {
      history.replace(`${location.pathname}/`);
    }
  }, [location, history]);

  return null;
}

const AllRoute = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <RouteRedirect />
        <TrailingSlashRedirect />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/about" component={AboutPageNew} />
          <Route exact path="/services" component={ServicePage} />
          <Route
            exact
            path="/maintenance-company"
            component={MaintenancePage}
          />
          <Route
            exact
            path="/maintenance-company/villa-maintenance"
            component={MaintenanceInner}
          />
          <Route
            exact
            path="/maintenance-company/appartment-maintenance"
            component={MaintenanceInner}
          />
          <Route
            exact
            path="/maintenance-company/swimming-pool"
            component={MaintenanceInner}
          />
          <Route
            exact
            path="/maintenance-company/ac-installation"
            component={MaintenanceInner}
          />
          <Route exact path="/fit-out-services" component={FitOutServices} />
          <Route
            exact
            path="/fit-out-services/kitchen-renovation"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/bathroom-renovation"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/painting-flooring"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/wardrobe-&-cabinets"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/villa-renovations"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/office-renovation"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/villa-extensions-&-doors"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/landscaping"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/interior-renovation"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/exterior-revamp"
            component={FitOutInner}
          />
          <Route
            exact
            path="/fit-out-services/lightning-installation"
            component={FitOutInner}
          />
          <Route
            exact
            path="/investment-support"
            component={InvestmentSupport}
          />
          <Route exact path="/portfolio" component={PortfolioPage} />
          <Route
            exact
            path="/portfolio-inner/:id"
            component={PortfolioInnerPage}
          />
          <Route exact path="/faq" component={FaqPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/contact/:policy" component={ContactPage} />
          <Route exact path="/blogs" component={BlogPage} />
          <Route exact path="/blogs/:categoryId" component={BlogPage} />
          <Route exact path="/blog/:blogRoute" component={BlogSinglePage} />
          {/* <Route
                exact
                path="/privacy-policy"
                component={PrivacyPolicy}
              /> */}
          <Route path="/*" component={ErrorPage} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AllRoute;
