// import React, { useEffect } from "react";
// import { useLocation, useHistory } from "react-router-dom";

// function RouteRedirect() {
//   let location = useLocation();
//   let history = useHistory();

//   console.log("🚀 ~ RouteRedirect ~ location:", location);
//   const SplitLocation = location?.pathname.split("/");

//   let RemoveENLocation = SplitLocation.filter((item) => item !== "en");

//   let JoinLocation = RemoveENLocation.join("/");
//   console.log("🚀 ~ RouteRedirect ~ JoinLocation:", JoinLocation);

//   useEffect(() => {
//     if (SplitLocation?.[1] == "en") {
//       history.push(JoinLocation);
//       console.log("🚀 ~ RouteRedirect ~ JoinLocation:", JoinLocation);
//     }
//   }, [location?.pathname]);
// }

// export default RouteRedirect;

import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

function RouteRedirect() {
  const location = useLocation();
  const history = useHistory();

  const path = location.pathname;
  console.log("🚀 ~ RouteRedirect ~ location:", location);

  // Define specific routes that need to be transformed to '/fit-out-services/*'
  const specificFitOutRoutes = [
    "/kitchen-renovation",
    "/bathroom-renovation",
    "/painting-flooring",
    "/wardrobe-&-cabinets",
    "/villa-renovations",
    "/office-renovation",
    "/villa-extensions-&-doors",
    "/landscaping",
    "/interior-renovation",
    "/exterior-revamp",
    "/lightning-installation",
  ];

  // Define specific routes that need to be transformed to '/maintenance-company/*'
  const specificMaintenanceRoutes = [
    "/villa-maintenance",
    "/appartment-maintenance",
    "/swimming-pool",
    "/ac-installation",
  ];

  useEffect(() => {
    const SplitLocation = path.split("/");
    const currentRoute = `/${SplitLocation[2]}`; // Get the current route after '/en'
    const isSpecificRoute = specificFitOutRoutes.includes(currentRoute);
    const isSpecificMaintRoute =
      specificMaintenanceRoutes.includes(currentRoute);

    if (SplitLocation[1] === "en") {
      if (isSpecificRoute) {
        // For specific routes, redirect to '/fit-out-services/*'
        const newPath = `/fit-out-services${currentRoute}`;
        console.log("🚀 ~ Redirecting to:", newPath);
        history.push(newPath);
      } else if (isSpecificMaintRoute) {
        // For specific routes, redirect to '/maintenance-company/*'
        const newPath = `/maintenance-company${currentRoute}`;
        console.log("🚀 ~ Redirecting to:", newPath);
        history.push(newPath);
      } else {
        // For other paths, simply remove '/en'
        const newPath = `/${SplitLocation.slice(2).join("/")}`;
        console.log("🚀 ~ Removing '/en' and redirecting to:", newPath);
        history.push(newPath);
      }
    }
  }, [path, history]);

  return null;
}

export default RouteRedirect;
